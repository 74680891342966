@import url('https://fonts.googleapis.com/css?family=Inconsolata|Playfair+Display&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: #151515;
  color: #fff;
  font-family: "Playfair Display", sans-serif;
  letter-spacing: 0.05em;
}
.main{
  margin-left: 225px;
}
a{
  text-decoration: none;
  color: #fff;
}
.section:first-child{
  height: 100vh;
}
.section{
  padding-top: 203px;
  padding-right: 75px;
  &__title{
    font-size: 96px;
    letter-spacing: 0.05em;
  }
}
.section-main{
  background-image: url(../img/bg-main.png);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: contain;
  &__name{
    font-size: 96px;
  }
  &__menu{
    display: inline-flex;
    flex-direction: column;
    margin-top: 50px;
  }
  &__menu a{
    color: #fff;
  }
  &__item{
    font-size: 24px;
    margin-bottom: 20px
  }
  &__item:last-child{
    margin-bottom: 0;
  }
  &__logo-mob{
    display: none;
    padding-top: 50px;
  }
  &__logo{
    &--mob{
      padding-top: 10px;
    }
  }
}
.header-social{
  &__content{
    width: 32px;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 237px;
    left: 109px;
    animation: my-animation 0.5s;
  }
  &__link{
    margin-top: 50px;
  }
  &__icon{
    width:32px;
    height:32px;
  }
  &__link:first-child{
    margin-top: 0;
  }
}

@keyframes my-animation {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

.header-menu{
  display: none;
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0;
  animation: my-animation 0.5s;
  &__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 138px 10px 74px;
    background: rgba(146, 146, 146, 0.5);
  }
  &__home-link{
    width: 80px;
  }
  &__logo{
    width: 100%;
  }
  &__nav{
    width: 350px;
    display: flex;
    justify-content: space-between;
    font-family: "Playfair Display" , sans-serif;
    font-size: 24px;
    height: 100%;
  }
  &__item{
    color: #ffffff;
  }
  &__mob{
    position: fixed;
    z-index: 1;
    width: 100%;
    &--pc-hiden{
      display: none;
    }
  }
  &__mob-nav-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: rgba(146, 146, 146, 0.5);
  }
  &__mob-content{
    display: none;
    height: 100vh;
    position: fixed;
    z-index: 1;
    width: 100%;
    background: rgba(255,255,255,.3);
  }
  &__mob-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 0 20px;
  }
  &__mob-title{
    font-size: 24px;
  }
  &__mob-items{
    display: inline-flex;
    flex-direction: column;
    margin-top: 30px;
    padding: 26px 34px 10px 34px;
  }
  &__mob-items a{
    color: #000;
    font-size: 24px;
  }
  &__mob-item{
    margin-bottom: 20px;
  }
  &__mob-item:last-child{
    margin-bottom: 0;
  }
  &__mob-social{
    margin-top: 40px;
    padding: 26px 34px 10px 34px;
  }
  &__mob-social-row{
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  &__item{
    &--active{
      color: #000;
      font-weight: bold;
    }
  }
  &__mob-content-container{
    background: #fff;
    color: #000;
  }
  &__mob-name{
    font-size: 24px;
  }
}

.section-about{
  &__title{
    font-size: 96px;
    letter-spacing: 0.05em;
  }
  &__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__info{
    max-width: 870px;
    margin-right: 40px;
  }
  &__desc{
    margin-top: 50px;
    font-family: "Inconsolata",sans-serif;
    font-size: 24px;
    letter-spacing: 0.05em;
  }
  &__to-learn-btn{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 36px;
  }
  &__to-learn-btn{
    font-family: "Inconsolata",sans-serif;
    font-size: 24px;
    letter-spacing: 0.05em;
  }
  &__to-learn-text{
    padding-right: 15px;
  }
  &__to-learn-link{
    width: 190px;
    text-align: end;
  }
  &__image{
    position: relative;
    z-index: -1;
    background: #000;
    width: 570px;
    height: 700px;
    margin-right: 30px;
  }
  &__img{
    position: absolute;
    left: 30px;
    bottom: 30px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.section-events{
  &__container{
    overflow: hidden;
    margin-top: 50px;
  }
  &__slides{
    display: flex;
    position: relative;
    left: 0;
    justify-content: space-between;
    transition: all ease 1s;
  }
  &__slide{
  }
  &__desc{
    max-width: 1020px;
  }
  &__image{
    position: relative;
    z-index: -1;
    width: 513px;
    height: 513px;
    background: #000;
    margin-right: 90px;
  }
  &__img{
    position: absolute;
    left: 30px;
    bottom: 30px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__h{
    font-size: 36px;
  }
  &__info-container{
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 460px;
    justify-content: space-between
  }
  &__desc{
    font-family: "Inconsolata",sans-serif;
    font-size: 24px;
    padding-top: 20px;
  }
  &__btns{
    display: flex;
  }
  &__buy-tiket-btn{
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    color: #000;
    padding: 10px 70px;
    font-size: 24px;
    margin-right: 30px;
  }
  &__buy-tiket-btn:hover{
    background: #151515;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    color: #FFFFFF;
  }
  &__learn-more-btn{
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    padding: 10px 70px;
    font-size: 24px;
  }
  &__learn-more-btn:hover{
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    color: #000;
  }
  &__pagination{
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    align-items: center;
    height: 30px;
  }
  &__pagination-page{
    display: none;
  }
  &__event{
    display: flex;
    align-items: center;
  }
  &__slide--active{
  }
}
.pagination{
  &__prev{
    margin-right: 15px;
    cursor: pointer;
  }
  &__page{
    margin-right: 15px;
    font-size: 24px;
    font-family: "Inconsolata",sans-serif;
    cursor: pointer;
    transition: all 0.2s ease;
    &--active{
      font-size: 32px;
    }
  }
  &__next{
    cursor: pointer;
  }
}

.section-store{
  &__container{
    display: flex;
    margin-top: 70px;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__item{
    display: flex;
    flex-direction: column;
    padding-bottom: 70px;
  }
  &__item:nth-child(3) img{
    object-fit: unset;
  }
  &__item-image{
    position: relative;
    left: -30px;
    z-index: -1;
    width: 420px;
    height: 470px;
    background: #000;
  }
  &__item-img{
    position: absolute;
    left: 30px;
    bottom: 30px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__price{
    font-family: "Inconsolata", sans-serif;
    font-size: 24px;
    margin-top: 10px;
  }
  &__show-btn{
    margin-top: 20px;
    width: 270px;
    border: 1px solid #FFFFFF;
    font-size: 24px;
    padding: 10px 77px;
    background: #FFFFFF;
    color: #000;
  }
  &__show-btn:hover{
    background: #151515;
    color: #FFFFFF;
  }
}
.footer{
  padding: 30px 0;
  &__container{
    padding: 0 80px;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
  }
  &__contacts-title{
    font-size: 24px;
    padding-bottom: 10px;
  }
  &__contacts-corp{
    padding-bottom: 10px;
    font-family: "Inconsolata", sans-serif;
  }
  &__social-mail{
    font-family: "Inconsolata", sans-serif;
  }
  &__social-text{
    padding-bottom: 20px;
  }
  &__contacts{
    margin-right: 30px;
  }
  &__social-links{
    display: flex;
    justify-content: space-between;
    max-width: 540px;
    &--pc-hiden{
      display: none;
    }
    &--mob-hidden{
      display: flex;
    }
  }
  &__socal-link{
    margin-right: 20px;
  }
  &__made{
    padding-top: 20px;
    display: flex;
    justify-content: center;
    font-size: 24px;
  }
  &__made-link{
    padding-left: 5px;
  }
}

.section-page-top{
  height: 100vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &--store{
    background: url('../img/store-bg.png');
    background-size: cover;
  }
  &--biography{
    background: url('../img/bg-biography.png');
    background-size: cover;
  }
  &--events{
    background: url('../img/events-bg.png');
    background-size: cover;
  }
  &__title{
    font-size: 300px;
  }
  &__page{
    font-size: 48px;
    padding-left: 28px;
    margin-top: -50px;
  }
  &__logo{
    display: none;
    position: absolute;
    top: 15px;
  }
}
.section-store-main{
  &__container{
    padding: 130px 225px 203px 225px;
  }
  &__image{
    display: flex;
    flex: 1;
  }
  &__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__product{
    display: flex;
    margin-bottom: 100px;
  }
  &__product:last-child{
    margin-bottom: 0;
  }
  &__info{
    display: flex;
    flex-direction: column;
    flex: 2;

    width: 100%;
    padding: 30px 0 60px 30px;
    &--first{
      background: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url('../img/store/item-2-bg.jpeg') right;
      background-size: cover;
    }
    &--second {
      background: linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)) , url('../img/store/item-5-bg.jpeg') right;
      background-size: cover;
    }
    &--third {
      background: url('../img/store/store-item-bg-2.png') right;
      background-size: cover;
    }
  }
  &__price-container{
    display: flex;
  }
  &__name{
    font-size: 36px;
  }
  &__price{
    font-size: 36px;
    margin-left: 20px;
  }
  &__desc{
    padding-top: 20px;
    font-family: "Inconsolata" , sans-serif;
    font-size: 24px;
    max-width: 560px;
  }
  &__ingridient{
    padding-top: 20px;
    font-family: "Inconsolata" , sans-serif;
    font-size: 24px;
  }
  &__shop-btn{
    margin-top: 100px;
    max-width: 270px;
    font-size: 24px;
    padding: 10px 77px;
    background: #FFFFFF;
    color: #000;
    border: 1px solid #fff;
  }
  &__shop-btn:hover{
    background: #151515;
    color: #FFFFFF;
  }
}

.section-biography-main{
  &__story-container{
    margin-top: 100px;
  }
  &__container{
    padding: 130px 225px 62px 225px;
  }
  &__title{
    font-size: 36px;
    padding-bottom: 20px;
  }
  &__desc{
    font-size: 24px;
    font-family: "Inconsolata", sans-serif;
    line-height: 140%;
    padding-top: 20px;
  }
  &__story-desc{
    font-size: 24px;
    font-family: "Inconsolata", sans-serif;
    line-height: 140%;
  }
  &__content{
    display: flex;
  }
  &__content:nth-child(3){
    padding-top: 20px;
  }
  &__content:nth-child(3){
    flex-direction: row-reverse;
  }
  &__story-img{
    width: 100%;
    max-width: 720px;
    float: right;
    margin-left: 35px;
  }
  &__content:nth-child(3) &__story-img{
    float: left;
    margin-right: 35px;
    margin-left: 0;
  }
  &__story-image{
    width: 100%;
  }
}
.section-events-main{
  &__container{
    padding: 120px 135px;
  }
  &__event{
    margin-bottom: 100px;
  }
}
@media (min-width: 500px)  and (max-width: 1200px){
  .section{
    &__title{
      font-size: 60px;
    }
  }
  .header-social{
    &__content{
      top: 180px;
      left: 60px;
    }
  }
  .section-about{
    &__container{
      flex-direction: column;
    }
    &__image{
      width: 100%;
      margin-top: 50px;
    }
    &__img{
    }
  }
  .section-events{
    &__event{
      flex-direction: column;
    }
    &__image{
      width: 100%;
      margin-right: 0;
    }
    &__info-container{
      height: 250px;
    }
    &__h{
      margin-top: 20px;
      font-size: 30px;
    }
    &__desc{
      font-size: 20px;
    }
    &__image{
      height: 400px;
      position: unset;
      z-index: unset;
    }
    &__img{
      position: unset;
    }
    &__pagination{
      padding-top: 20px;
    }
    &__buy-tiket-btn{
      padding: 10px 20px;
      font-size: 22px;
    }
    &__learn-more-btn{
      padding: 10px 20px;
      font-size: 22px;
    }
  }
  .section-biography-main{
    &__story{
      flex-direction: column;
    }
    &__story-img{
      margin-top: 40px;
      max-width: 640px;
    }
    &__story-image{
      width: 100%;
    }
    &__container {
      padding: 130px 120px 62px 120px;
    }
    &__content{
      max-width: unset;
    }
  }
  .section-events-main{
    &__container{
      padding: 120px 120px 60px 120px;
    }
  }
  .section-page-top{
    background-position: center;
  }
  .section-store-main{
    &__container{
      padding: 120px 120px 60px 120px;
    }
  }
}
@media all and (max-width: 900px){
  .main{
    margin-left: 150px;
  }
  .header-menu{
    &--mob-hiden{
      display: none;
    }
  }
  .header-menu{
    &__mob{
      display: block;
      &__name{
        font-size: 24px;
      }
    }
    &__mob-btn{
      cursor: pointer;
    }
  }
  .section-store{
    &__item{
      width: 100%;
    }
    &__item-image{
      width: 100%;
    }
  }
  .section-page-top{
    &__title{
      font-size: 200px;
    }
    &__page{
      font-size: 36px;
      margin-top: -40px;
    }
  }
  .section-biography-main{
    &__container{
      padding: 130px 70px 62px;
    }
  }
  .section-store-main{
    &__product{
      flex-direction: column;
    }
  }
  .footer{
    &__social-links{
      justify-content: unset;
      flex-wrap: wrap;
    }
  }
}
@media (min-width: 500px) and (max-width: 768px){
  .main{
    margin-left: 100px;
  }
  .header-social{
    &__content{
      display: none;
    }
  }
  .section-events{
    &__info-container{
      height: 300px;
    }
  }
  .section{
    padding-top: 100px;
  }
  .section-events-main{
    &__container{
      padding: 60px 60px 20px 60px;
    }
  }
  .section-store-main{
    &__container{
      padding: 60px;
    }
  }
  .footer{
    &__container{
      flex-direction: column;
      padding: 0 70px 10px 70px;
      font-size: 14px;
      align-items: unset;
    }
    &__contacts-title{
      font-size: 24px;
    }
    &__contacts-corp{
      font-size: 18px;
      padding-top: 20px;
    }
    &__contacts-mail{
      font-size: 18px;
      padding-top: 10px;
    }
    &__social-text{
      padding-top: 50px;
      font-weight: bold;
      font-size: 18px;
    }
    &__social-links{
      padding-top: 30px;
      flex-direction: column;
      &--pc-hiden{
        display: flex;
      }
      &--mob-hidden{
        display: none;
      }
    }
    &__social-row{
      display: flex;
      justify-content: space-between;
    }
    &__social-row:first-child{
      margin-right: 0;
      margin-bottom: 20px;
    }
    &__contacts-created{
      font-size: 18px;
    }
  }
  .section-biography-main{
    &__desc{
      font-size: 20px;
    }
  }
}

.container-release {
  display: flex;
  text-align: center;

  & > .section-events__btns:nth-child(2) {
    margin-left: 25px;
  }
}


@media all and (max-width: 500px){

  .container-release {
    flex-direction: column;

    & > .section-events__btns {
      margin-left: 0;
    }
  }


  .main{
    margin: 0;
  }
  .section{
    padding: 0;
    padding-bottom : 60px;
    height: unset;
    &__title{
      text-align: center;
      font-size: 24px;
      margin-top: 20px;
    }
  }
  .section-main{
    height: 1000px;
    background-image: url("../img/bg-main-mob.png");
    background-origin: border-box;
    background-size: cover;
    background-position: 50% 30%;
    &__logo{
      display: none;
    }
    &__menu{
      display: none;
    }
    &__name{
      text-align: center;
    }
    &__logo-mob{
      display: block;
      text-align: center;
    }
  }
  .header-social{
    &__content{
      display: none;
    }
  }
  .section-about{
    &__container{
      flex-direction: column;
    }
    &__info-container{
      margin-top: 30px;
      padding: 50px 20px 20px 20px;
      background-image:  url("../img/about-me-mob.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    &__desc{
      font-size: 14px;
      margin: 0;
    }
    &__image{
      display: none;
    }
    &__info{
      margin: 0;
    }
    &__to-learn-text{
      font-size: 14px;
      padding-top: 40px;
    }
    &__to-learn-arrow{
      width: 25px;
    }
  }
  .section-store{
    &__container{
      flex-direction: column;
    }
  }
  .section-events{
    &__container{
      overflow: unset;
      margin-top: 30px;
    }
    &__slides{
      height: unset;
      flex-direction: column;
      position: unset;
    }
    &__event{
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;
    }
    &__info-container{
      justify-content: flex-end;
      max-height: 350px;
      height: 100%;
      min-height: 250px;
    }
    &__info{
      padding: 20px 20px;
      background-size: cover;
      max-height: 444px;
      &--first{
        background: linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8)), url("../img/store/final.png") center;
        width: 100%;
        background-size: cover;
      }
      &--second{
        background: linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8)), url("../img/store/item-4.jpeg") center;
        width: 100%;
        background-size: cover;
      }
      &--third{
        background: linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8)), url("../img/events/event.png") ;
        background-size: cover;
      }
    }
    &__h{
      font-size: 18px;
    }
    &__desc{
      font-size: 14px;
    }
    &__image,&__pagination{
      display: none;
    }
    &__btns{
      margin-top: 40px;
      flex-direction: column;
    }
    &__buy-tiket-btn{
      margin-right: 0;
      margin-bottom: 20px;
      width: 270px;
    }
    &__learn-more-btn{
      padding: 10px 66px;
      width: 270px;
    }
    &__pagination-page{
      display: block;
      font-size: 36px;
      font-family: "Inconsolata", sans-serif;
      opacity: 0.6;
      text-align: end;
    }
    &__event:last-child{
      margin-bottom: 0;
    }
  }
  .section-store{
    margin-bottom: 0;
    &__item{
      padding-bottom: 70px;
    }
    &__item:last-child{
      padding-bottom: 0;
    }
    &__item-image{
      position: unset;
      width: 100%;
      height: unset;
    }
    &__item-img{
      position: unset;
    }
    &__price{
      padding-left: 30px;
    }
    &__show-btn{
      display: flex;
      align-self: center;
    }
    &__container{
      margin-top: 30px;
    }
  }
  .footer{
    &__container{
      flex-direction: column;
      padding: 0 27px;
      font-size: 14px;
      align-items: unset;
    }
    &__contacts-title{
      font-size: 18px;
    }
    &__contacts-corp{
      padding-top: 20px;
    }
    &__social-mail{
      padding-top: 10px;
    }
    &__social-text{
      padding-top: 50px;
      font-weight: bold;
    }
    &__social-links{
      padding-top: 30px;
      flex-direction: column;
      &--pc-hiden{
        display: flex;
      }
      &--mob-hidden{
        display: none;
      }
    }
    &__social-row{
      display: flex;
      justify-content: space-between;
    }
    &__social-row:first-child{
      margin-right: 0;
      margin-bottom: 20px;
    }
    &__made{
      font-size: 18px;
      padding-top: 30px;
    }
  }
  .section-page-top{
    background-position: center;
    &__title{
      font-size: 96px
    }
    &__page{
      font-size: 24px;
      padding-top: 40px;
      padding-left: 10px;
    }
    &__logo{
      display: block;
    }
  }
  .section-store-main{
    &__container{
      padding: 0;
      padding-top: 50px;
    }
    &__product{
      flex-direction: column;
      margin-bottom: 40px;
      justify-content: space-between;
    }
    &__info{
      flex-direction: column;
      background-size: cover;
    }
    &__desc{
      font-size: 14px;
      max-width: 335px;
    }
    &__ingridient{
      font-size: 14px;
    }
    &__shop-btn{
      margin-top: 40px;
    }
  }
  .section-biography-main{
    &__container{
      padding: 50px 20px;
    }
    &__content{
      padding-top: 30px;
      flex-direction: column-reverse;
    }
    &__content:nth-child(3) &__story-desc{
      margin: 0;
    }
    &__content:nth-child(3){
      flex-direction: column-reverse;
    }
    &__story{
      flex-direction: column-reverse;
      margin-top: 30px;
    }
    &__story-img{
      margin-left: -20px;
      margin-right: -20px;
      width: unset;
      padding-bottom: 10px;
    }
    &__content:nth-child(3) &__story-img{
      margin-left: -20px;
      margin-right: -20px;
    }
    &__story-image{
      width: 100%;
    }
    &__title{
      font-size: 24px;
    }
    &__desc{
      font-size: 14px;
    }
    &__story-desc{
      font-size: 14px;
      margin: 0;
    }
  }
  .section-events-main{
    &__container{
      padding:  100px 0;
    }
  }
}
